import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/home',
    component: () => import('../views/layout.vue'),
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/views/home.vue'),
      },
      // {
      //   path: '/recharge',
      //   name: 'recharge',
      //   component:() => import( '../views/views/recharge.vue'),
      // },
      {
        path: '/aboutus',
        name: 'aboutus',
        component: () => import('../views/views/aboutus.vue'),
      },
      {
        path: '/agreement',
        name: 'agreement',
        component: () => import('../views/views/agreement.vue'),
      },
      {
        path: '/privacy',
        name: 'privacy',
        component: () => import('../views/views/privacy.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
